import React from "react";
import logo from "../resources/logo.png";
//import resume from "../resources/resume.pdf";

function Header() {
  return (
    <div id="Header" className="banner justify-content-between container-fluid">
      <div className="row flex-row align-items-center">
        <div className="col">
          <div className="row">
            <button
              style={{ all: "unset", cursor: "pointer" }}
              onClick={() => window.location.replace("/")}
            >
              <img
                src={logo}
                alt="Logo"
                className="img-fluid"
                id="Header-logo"
              />
            </button>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <button
              className="Header-button col"
              onClick={() => window.location.replace("/#About")}
            >
              About
            </button>
            <button
              className="Header-button col"
              onClick={() => window.location.replace("/#Projects")}
            >
              Projects
            </button>
            <button
              className="Header-button col"
              onClick={() => window.location.replace("/#Form")}
            >
              Contact
            </button>
            {/* <button className="Header-button col">
                        <a href={resume} target="_blank" rel="noopener noreferrer" className="Header-link">Resume</a></button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
