import React from "react";

function Home() {
  return (
    <div className="container-fluid">
      <div id="About" className="justify-content-center row">
        <div id="Home">
          <h2 className="col-sm-12 text">About</h2>
          <p className="col-sm-12 text">
            Currently I'm working full time as a software engineer. In my free
            time, I like to tinker with Python and Unity projects. I find that
            these projects help greatly sharpen my coding skills by exposing me
            to new ideas, new points of view, and new technologies. My
            programming journey started in high school, where I taught myself
            Python. After high school, I enrolled in and graduated from a full
            stack coding boot camp.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
