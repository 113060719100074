import React, { createRef } from 'react';
import { useForm, ValidationError } from '@formspree/react';

function ContactForm() {
    // functionality for formspree
    const [state, handleSubmit] = useForm("mwkaawww");
    // react ref
    const formRef = createRef();

    // If the form is submitted successfully, this message is displayed
    var thankyou = <p></p>
    if (state.succeeded) {
        thankyou = <p style={{color: 'white', marginTop: '30px', marginBottom: '30px'}}>Thank you for your message, I will get back to you shortly!</p>
    }

    return (
        <form onSubmit={handleSubmit} id="Form" ref = {formRef} className="row justify-content-center">
            {thankyou}
            <h2 className="col-sm-12">Email me!</h2>
            <label className="col-sm-12" htmlFor="email">
                Email Address
            </label>
            <input className="col-sm-12 no-outline"
                id="email"
                type="email" 
                name="email"
                style={{backgroundColor: 'rgba(0, 0, 0, 0.5)', color: 'white', margin: '20px'}}/>
            <ValidationError 
                prefix="Email" 
                field="email"
                errors={state.errors}
            />
                <label className="col-sm-12" htmlFor="email">
                Message
            </label>
            <textarea
                className="col-sm-12 no-outline"
                id="message"
                name="message"
                style={{height: '400px', margin: '20px'}}
            />
            <ValidationError 
                prefix="Message" 
                field="message"
                errors={state.errors}
            />
            <button type="submit" disabled={state.submitting}
                id="emailSubmit"
                className="col-sm">
                Submit
            </button>
        </form>
  );
}
export default ContactForm;