import React from 'react'
import Particles from "react-tsparticles";
import {isMobile} from 'react-device-detect'

//import particleSettings from  './particleSettings.json'

function ParticleComponent() {

    const settings = 
        {
          autoPlay: true,
          background: {
            color: {
              value: '#000'
            },
            image: '',
            position: '',
            repeat: '',
            size: '',
            opacity: 1
          },
          backgroundMask: {
            composite: 'destination-out',
            cover: {
              color: {
                value: '#000'
              },
              opacity: 1
            },
            enable: false
          },
          detectRetina: true,
          fpsLimit: 60,
          infection: {
            cure: false,
            delay: 0,
            enable: false,
            infections: 0,
            stages: []
          },
          interactivity: {
            detectsOn: 'window',
            events: {
              onClick: {
                enable: false,
                mode: []
              },
              onDiv: {
                ids: [],
                enable: false,
                mode: [],
                type: 'circle'
              },
              onHover: {
                enable: true,
                mode: 'grab',
                parallax: {
                  enable: true,
                  force: 25,
                  smooth: 10
                }
              },
              resize: true
            },
            modes: {
              bubble: {
                distance: 200,
                duration: 0.4
              },
              connect: {
                distance: 80,
                links: {
                  opacity: 0.5
                },
                radius: 100
              },
              grab: {
                distance: 400,
                links: {
                  blink: false,
                  consent: false,
                  opacity: 1
                }
              },
              push: {
                quantity: 4
              },
              remove: {
                quantity: 2
              },
              repulse: {
                distance: 200,
                duration: 0.4,
                speed: 1
              },
              slow: {
                factor: 3,
                radius: 200
              }
            }
          },
          particles: {
            collisions: {
              enable: false,
              mode: 'bounce'
            },
            color: {
              value: 'random',
              animation: {
                h: {
                  count: 0,
                  enable: true,
                  offset: 0,
                  speed: 50,
                  sync: false
                }
              }
            },
            links: {
              blink: false,
              color: {
                value: 'random'
              },
              consent: false,
              distance: 300,
              enable: true,
              opacity: 1,
              shadow: {
                blur: 5,
                color: {
                  value: 'random'
                },
                enable: false
              },
              triangles: {
                enable: true
              },
              width: 1,
              warp: false
            },
            move: {
              attract: {
                enable: true,
                rotate: {
                  x: 3000,
                  y: 3000
                }
              },
              direction: 'none',
              enable: true,
              noise: {
                delay: {
                  random: {
                    enable: false,
                    minimumValue: 0
                  },
                  value: 0
                },
                enable: false
              },
              outMode: 'out',
              random: false,
              speed: 2,
              straight: false,
              trail: {
                enable: false,
                length: 10,
                fillColor: {
                  value: 'random'
                }
              },
              vibrate: false,
              warp: false
            },
            number: {
              density: {
                enable: false,
                area: 800,
                factor: 1000
              },
              limit: 0,
              value: 100
            },
            opacity: {
              random: {
                enable: true,
                minimumValue: 0.7
              },
              value: {
                min: 0.7,
                max: 1
              },
              animation: {
                count: 0,
                enable: true,
                speed: 0.5,
                sync: false,
                destroy: 'none',
                startValue: 'random',
                minimumValue: 0.3
              }
            },
            rotate: {
              animation: {
                enable: false,
                speed: 0,
                sync: false
              },
              direction: 'clockwise',
              random: false,
              value: 0
            },
            shadow: {
              blur: 0,
              color: {
                value: 'random'
              },
              enable: false,
              offset: {
                x: 0,
                y: 0
              }
            },
            shape: {
              options: {},
              type: 'circle'
            },
            size: {
              animation: {
                destroy: 'none',
                enable: false,
                minimumValue: 0,
                speed: 5,
                startValue: 'max',
                sync: false
              },
              random: {
                enable: true,
                minimumValue: 3
              },
              value: 3
            },
            stroke: {
              color: {
                value: 'random'
              },
              width: 0,
              opacity: 1
            },
            twinkle: {
              lines: {
                enable: false,
                frequency: 0.05,
                opacity: 1
              },
              particles: {
                enable: false,
                frequency: 0.05,
                opacity: 1
              }
            },
            zIndex: {
              random: {
                enable: true,
                minimumValue: 0
              },
              value: 0,
              opacityRate: 1,
              sizeRate: 1,
              velocityRate: 1
            }
          },
          pauseOnBlur: true,
          pauseOnOutsideViewport: true,
          responsive: [true],
          themes: [],
          zLayers: 100
        };
      
    if (isMobile){
        settings.particles.number.value = 20;
        settings.particles.links.distance = 600;
    }
    
    return (
          <Particles
            id="tsparticles"
            options={settings}
          />
    )
}

export default ParticleComponent
