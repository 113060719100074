import React, {useState, useRef, useEffect} from 'react';

function FadeInSection(props) {
    // tracks whether or not section is visible
    const [isVisible, setVisible] = useState(false);
    // create DOM reference
    const domRef = useRef();
    
    useEffect(() => {
        // create new instance of IntersectionObserver and pass in a callback
        // which will toggle visibility if this component comes into view
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setVisible(entry.isIntersecting);
                }
            });
        });
        // this "observes" our DOM node and tells the IntersectionObserver to determine whether or not
        // the component is in view
        observer.observe(domRef.current);
        // unobserve must be called after observation for IntersectionObserver to work properly
        return () => observer.unobserve(domRef.current);
    }, []);

    return (
        // When toggled to visible, the css class will change and on this
        // change a fade-in animation will start
        <div
            className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
            ref={domRef}
        >
            {props.children}
        </div>
    );
}

export default FadeInSection;