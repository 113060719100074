import React from "react";
import logo from "../resources/logo.png";

function Footer() {
  return (
    <div className="container-fluid justify-content-between" id="Footer">
      <div className="row">
        <div className="col-sm">
          <h4>Thanks for visiting!</h4>
          <img
            src={logo}
            alt="Logo"
            style={{
              maxWidth: "32px",
              maxHeight: "32px",
              marginBottom: "15px",
            }}
          ></img>
        </div>
        <div className="col-sm justify-content-center">
          <h4 className="col-sm-12 d-table">Contact</h4>
          <a
            className="col-sm-12 d-table"
            href="#/Form"
            onClick={() => window.location.replace("/#Form")}
          >
            Email
          </a>
        </div>
        <div className="col-sm justify-content-center">
          <h4 className="col-sm-12 d-table">More</h4>
          <a
            className="col-sm-12 d-table"
            target="_blank"
            rel="noopener noreferrer"
            href="https://github.com/vincentslee"
          >
            GitHub
          </a>
          {/* <a  className='col-sm-12 d-table' target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/vincent-lee-4aabb01b0/">LinkedIn</a> */}
          {/*                         <a  className='col-sm-12 d-table' target="_blank" rel="noopener noreferrer" href="https://vincentslee.itch.io/">itch.io</a>
           */}{" "}
        </div>
      </div>
    </div>
  );
}

export default Footer;
