import React from 'react'

import screenshot1 from '../resources/screenshot1.png'
import screenshot2 from '../resources/screenshot2.png'

function Projects() {

      
    
    return (
        <div  className="justify-content-center container-fluid">
            <div className="row">
                <div id="Projects">
                    <h3>Projects</h3>
                    <div className="row" style={{display: 'flex'}}>

                    <div className="col-sm-4 project">
                        <h4 className="project-title">Sorting Algorithm Visualizer</h4>
                        <img className="project-img" src={screenshot1} title="Sorting Algorithm Visualizer Screenshot" style={{width: '100%', height: '30vh'}}></img>
                        <button onClick={(e)=>{e.preventDefault(); window.open("https://github.com/vincentslee/Sorting-Visualizer")}}>View Code</button>
                        <button onClick={(e)=>{e.preventDefault(); window.open("https://vincentslee.github.io/Sorting-Visualizer/")}}>View Live</button>
                        <p style={{height: '10vh'}}>A tool displaying how different sorting algorithms work</p>
                        <h4>Built with: </h4>
                        <p>[React.js] [Bootstrap]</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Projects
