import React, { Component } from "react";

import "./App.css";
import Header from './components/Header'
import ParticleComponent from './components/ParticleComponent';
import Home from './components/Home';
import Info from './components/Info';
import Projects from './components/Projects';
import Footer from './components/Footer';
import ContactForm from "./components/Form";
import FadeInSection from "./components/FadeInSection";

class App extends Component {
  render() {
    return (
      <div className="App">
        <div id="Main" className="container-fluid justify-content-between">
          
          <Header/>

          <div id="Welcome" className="row">
            <h3 className="col-sm-12">Hi, my name is Vincent Lee,</h3>
            <h2 className="col-sm-12">Welcome to my portfolio.</h2>
            <p className="col-sm-12">[Scroll to see more]</p>
          </div>

          <FadeInSection><Home/></FadeInSection>

          <FadeInSection>
            <Info/>
          </FadeInSection>
          <FadeInSection><Projects/></FadeInSection>
          <FadeInSection><ContactForm/></FadeInSection>
          
          <FadeInSection><Footer/></FadeInSection>

        </div>
        <ParticleComponent/>
      </div>
    );
  }
}

export default App;
